import React from "react"
import PropTypes from "prop-types"
import styles from "./articleList.module.css"

import Article from "./article"

const ArticleList = ({ posts }) => (
  <div className={styles.articleList}>
    {posts.map(({ node }) => (
      <div className={styles.articleListItem} key={node.fields.slug}>
        <Article
          category={node.frontmatter.category}
          date={node.frontmatter.date}
          slug={node.fields.slug}
          title={node.frontmatter.title}
        />
      </div>
    ))}
  </div>
)

ArticleList.propTypes = {
  posts: PropTypes.array.isRequired,
}

export default ArticleList
