import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styles from "./article.module.css"

const Article = ({ category, date, slug, title }) => (
  <article className={styles.article}>
    <h2 className={styles.articleTitle}>
      <Link to={`/${slug}`}>{title}</Link>
    </h2>
    <div className={styles.articleMeta}>
      <time className={styles.articleDate} dateTime={date}>{date}</time>
      <ul className={styles.articleCategoryList}>
        <li className={styles.articleCategoryListItem}>
          {category}
        </li>
      </ul>
    </div>
  </article>
)

Article.propTypes = {
  category: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default Article
